import React from 'react';
import Nav from './Nav';

const SideDrawer = (props) => {
  return (
    <>
      <div
        id="side-drawer"
        className={"position-fixed " + (props.visible ? "visible" : "")}>

        <div className="h-100 side-drawer-content">
          <div className="side-drawer-header bg-green-light text-center">
            <a title="Logo" href={ process.env.PUBLIC_URL + "/" }>
              <img
                className="side-drawer-img rounded-circle mb-3"
                src="assets/img/secare/sidebar.jpg"
                alt="Paula & Anni"
              />
            </a>
            <h4>Secare Salon</h4>
          </div>
          <div className="side-drawer-nav p-4">
            <Nav for="mobile" handleClose={props.handleClose} handleSetSection={props.handleSetSection} />
            <div className="close-container">
              <i
                onClick={props.handleClose}
                className="close-side-drawer fas fa-sign-out-alt"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        id="side-drawer-void"
        className={"position-fixed " + (props.visible ? "d-block" : "d-none")}
        onclick="closeSideDrawer()">
      </div>
    </>
  );
};

export default SideDrawer;
