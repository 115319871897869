import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import AboutUs from '../blocks/pages/AboutUs';
import Philosophy from "../blocks/pages/Philosophy";
import Book from "../blocks/pages/Book";
import ContactUs from "../blocks/pages/ContactUs";
import PageTitleHome from "../blocks/page-title/PageTitleHome";
import Prices from "../blocks/pages/Prices";


class Home extends React.Component {

  constructor(props) {
    super(props);
    document.body.classList.add( 'home' );
    document.body.classList.add( 'header-absolute-true' );

  }

  render() {
    return (
      <Fragment>
        <MetaTags>
          <meta charSet="UTF-8" />
          <title>Home | Secare Friseursalon</title>

          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="description" content="Secare Friseursalon - Unsere Aufgabe: Menschen verschönern und ihnen helfen, sich von ihrer besten Seite zu zeigen!" />
          <meta name="keywords" content="friseur berlin" />
          <meta name="robots" content="index, follow, noodp" />
          <meta name="googlebot" content="index, follow" />
          <meta name="google" content="notranslate" />
          <meta name="format-detection" content="telephone=no" />
          <meta property="og:title" content="Secare Friseursalon Berlin" />
          <meta property="og:description" content="Unsere Aufgabe: Menschen verschönern und ihnen helfen, sich von ihrer besten Seite zu zeigen!" />
          <meta property="og:url" content="https://secarehair.de" />
          <meta property="og:image" content="https://secarehair.de/assets/img/secare/logo.svg" />
        </MetaTags>

        <Loading />

        <Header renderNav={true} logoColor="light" />

        <main id="main" className="site-main content-no-spacing">
          <div className="content">
            <div className="clearfix">
              <PageTitleHome />
      
              <Prices />

              <AboutUs />

              <Philosophy />


              <Book />


              <ContactUs />
            </div>
          </div>
        </main>

        <Footer />
      </Fragment>
    );
  }
};

export default Home;
