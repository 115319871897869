import React from 'react';

const AboutUs = () => {
  return (
    <section name="about-us" id="about-us" className="scroll-to block spacer p-top-xl">
      <div className="wrapper">
        <div className="row gutter-width-sm">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="title">
              <h2>Über Uns</h2>
            </div>
          </div>
        </div>
        <div className="row gutter-width-sm">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="team-img team-img-mobile d-block d-sm-none" style={ { backgroundImage: 'url(assets/img/secare/team_mobile.jpg)' } }>
            </div>
            <div className="team-img d-none d-sm-block" style={ { backgroundImage: 'url(assets/img/secare/team.jpg)' } }>
            </div>

            <div className="team-text">

              <p>
                Hi! Wir sind Anni und Paula.
              </p>

              <p>
                Zwei beste Freundinnen, die eine Leidenschaft teilen: Wir wollen Menschen verschönern und ihnen dabei helfen, sich von ihrer besten Seite zu zeigen!
              </p>

              <p>
                Vor 7 Jahren haben wir uns bei der Arbeit kennen und lieben gelernt und sind uns seitdem gegenseitig nicht mehr von der Seite gewichen. Von Tag eins waren wir unzertrennlich, verstanden uns blind und ohne Worte. Wir bemerkten schnell, dass wir nicht nur ähnliche Ideale und Vorstellungen im Privaten hatten, sondern auch mit derselben Arbeitseinstellung unseren Beruf ausübten.
                <br />
                Daher war es nur eine Frage der Zeit, bis wir unser eigenes kleines Baby erschaffen würden.
              </p>


              <p>
                <strong>Secare - There it is!</strong>
              </p>

              <p>
                Wir stehen beide mit Herzblut hinter dem, was wir tun, haben Spaß an der Arbeit und immer eine gute Portion Humor in petto.
                <br />
                Es ist uns sehr wichtig, dass Du dich bei uns wohlfühlst und wir geben alles, damit du sowohl mit dem Ergebnis als auch mit deinem Aufenthalt bei Secare vollkommen zufrieden bist. Ehrenwort!
              </p>

            </div>


          </div>
        </div>

        <div className="row gutter-width-sm">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div className="team-member">
              <div className="img object-fit">
                <div className="object-fit-cover">
                  <img src="assets/img/secare/anni.jpg" alt="Anni" />
                </div>
              </div>

              <div className="team-member-content">
                <div className="team-member-position">
                  <p>Co-Founder / Master Hairstylistin / Coloristin</p>
                </div>

                <h4 className="team-member-t-head">Anni Schorb</h4>

                <div className="team-member-description">
                  <p>
                    Im beschaulichen Baden-Württemberg geboren und aufgewachsen, spürte sie schon früh, dass sie mehr vom Leben will. So zog es sie in jungen Jahren nach Berlin, um sich selbst sowie ihren Beruf intensiver zu entdecken und zu erkunden. Körperkunst sowie Haarkunst zählen zu den großen Leidenschaften in ihrem Leben. Stehen bleiben gibt es nicht - Neugierde sowie der stetige Wunsch nach Weiterentwicklung treiben sie an.
                    <br />                    
                    Nachdem Sie etliche Länder bereist und diverse Ausflüge in andere Berufszweige gemacht hat, kommt sie immer wieder zurück zur Base - Berlin und die Arbeit im Salon.
                    <br />
                    Sie lebt ihren Beruf mit Leidenschaft seit nunmehr 12 Jahren, daher kannst du dich guten Gewissens in ihre Hände fallen lassen.
                  </p>
                </div>
              </div>

              <nav className="team-member-nav-items">
                <ul className="nav">
                  <li className="nav-item">
                    <a title="Facebook" href="https://www.facebook.com/annchristine.schorb"><i className="fab fa-facebook-f"></i></a>
                  </li>

                  <li className="nav-item">
                    <a title="Instagram" href="https://www.instagram.com/anni.solala/"><i className="fab fa-instagram"></i></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div className="team-member">
              <div className="img object-fit">
                <div className="object-fit-cover">
                  <img src="assets/img/secare/paula.jpg" alt="Paula" />
                </div>
              </div>

              <div className="team-member-content">
                <div className="team-member-position">
                  <p>Co-Founder / Hairstylistin / Coloristin</p>
                </div>

                <h4 className="team-member-t-head">Paula Schlawig</h4>

                <div className="team-member-description">
                  <p>
                    In Berlin geboren und aufgewachsen spiegelt sie das Abbild des urbanen Berliner Lebens wieder.<br />
                    Selbstdarstellung war schon immer ein wichtiger Teil ihres Lebens - egal ob durch Kleidungsstil, Tattoos oder Haarkunst. Doch nicht nur wenn es um ihren eigenen Körper geht, setzt Paula hohe Maßstäbe, sie verfeinert mit ihrer gestalterischen Energie auch gern den Look anderer. Sie hat das Friseurhandwerk gefunden, um ihre schöpferische Energie zu kanalisieren und ihrem Ideenreichtum freien Lauf zu lassen.<br />
                  Paula ist die perfekte Ansprechpartnerin, wenn es um ausgefallene Wünsche geht - durch 9 Jahre Berufserfahrung bringt sie das nötige Know-how mit, dass du für die Umsetzung deiner Haarträume brauchst. 
                  </p>
                </div>
              </div>

              <nav className="team-member-nav-items">
                <ul className="nav">
                  <li className="nav-item">
                    <a title="Facebook" href="https://www.facebook.com/nodope.nohopeee"><i className="fab fa-facebook-f"></i></a>
                  </li>

                  <li className="nav-item">
                    <a title="Instagram" href="https://www.instagram.com/nodope_nohope/"><i className="fab fa-instagram"></i></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <hr />

        <div className="row gutter-width-sm">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="title">
              <h2 className="salon-headline">Der Salon</h2>
            </div>
          </div>
        </div>

        <div className="row gutter-width-sm">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p className="salon-text">
              Nachfolgend einige Impressionen von unserem Salon in Prenzlauer Berg.<br />
              Weitere Bilder von den Räumlichkeiten sowie unseren Arbeiten findet ihr auf unserer <a target="_blank" href="https://www.instagram.com/secare.hair/">Instagram Seite</a>.
            </p>
          </div>
        </div>

        <div className="row gutter-width-sm salon-pics">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div className="salon-picture">
              <img src="assets/img/secare/salon/hochkant01.jpg" alt="Bild vom Salon" />
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div className="salon-picture">
              <img src="assets/img/secare/salon/hochkant02.jpg" alt="Bild vom Salon" />
            </div>
          </div>
        </div>

        <div className="row gutter-width-sm salon-pics salon-pics-margin">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div className="salon-picture">
              <img src="assets/img/secare/salon/01.jpg" alt="Bild vom Salon" />
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div className="salon-picture">
              <img src="assets/img/secare/salon/02.jpg" alt="Bild vom Salon" />
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

export default AboutUs;
