import React from 'react';

const AboutsUs = () => {
  return (
    <section name="book" id="book" className="bg-green-light scroll-to spacer block no-padding including-image p-top-xl">
      <div className="text-wrapper text-center">
        <div className="title">
          <h2>Buche jetzt deinen Termin</h2>
        </div>
  
        <div className="button-wrapper">
          <a href="https://secare.enfore.com" target="_blank" className="btn btn-outline-secondary">Buchen</a>
        </div>
  
        <div className="content opening-hours">
          <h4>Öffnungszeiten</h4>

          <p><strong>MO</strong> - Geschlossen</p>
          <p><strong>DI</strong> - 9-18 Uhr</p>
          <p><strong>MI</strong> - 9-18 Uhr</p>
          <p><strong>DO</strong> - 11-20 Uhr</p>
          <p><strong>FR</strong> - 9-18 Uhr</p>
          <p><strong>SA</strong> - 10-16 Uhr</p>
          <p><strong>SO</strong> - Geschlossen</p>
        </div>
      </div>

      <div className="block img img-gray">
      </div>

    </section>
  );
};

export default AboutsUs;
