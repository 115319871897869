import React from 'react';
import { Link } from 'react-scroll';

const Nav = (props) => {
  const handleSetPageTitleActive = () => props.handleSetSection('page-title');
  const handleSetAboutUsActive = () => props.handleSetSection('about-us');
  const handleSetPhilosophyActive = () => props.handleSetSection('philosophy');
  const handleSetPricesActive = () => props.handleSetSection('prices');
  const handleSetBookActive = () => props.handleSetSection('book');
  const handleSetContactUsActive = () => props.handleSetSection('contact-us');

  if (props.for === 'desktop' && window.innerWidth < 768) {
    return null;
  }

  // Unnecessary, included just for the sake of understanding
  // if (props.for === 'mobile' && window.innerWidth >=  768) {
  //   return;
  // }

  return (
    <ul className="nav navbar-nav">
      <li className="d-none nav-item">
        <Link href="#page-title"
          to="page-title"
          spy={ true }
          smooth={ true }
          duration={ 0 }
          offset={-70}
          activeClass="active"
          onSetActive={handleSetPageTitleActive}
          className="page-title nav-link scroll-to-id">
        </Link>
      </li>
      <li className="nav-item">
        <Link href="#prices"
          to="prices"
          spy={ true }
          smooth={ true }
          duration={ 0 }
          offset={-70}
          activeClass="active"
          onSetActive={handleSetPricesActive}
          className="prices nav-link scroll-to-id">
          Preise
        </Link>
      </li>
      <li className="nav-item">
        <Link href="#about-us"
          to="about-us"
          spy={ true }
          smooth={ true }
          duration={ 0 }
          offset={-70}
          activeClass="active"
          onSetActive={handleSetAboutUsActive}
          className="about-us nav-link scroll-to-id">
          Über Uns 
        </Link>
      </li>
      <li className="nav-item">
        <Link href="#philosophy"
          to="philosophy"
          spy={ true }
          smooth={ true }
          duration={ 0 }
          offset={-70}
          activeClass="active"
          onSetActive={handleSetPhilosophyActive}
          className="products nav-link scroll-to-id">
          Philosophie
        </Link>
      </li>
      <li className="nav-item">
        <Link href="#book"
          to="book"
          spy={ true }
          smooth={ true }
          duration={ 0 }
          offset={-70}
          activeClass="active"
          onSetActive={handleSetBookActive}
          className="book nav-link scroll-to-id">
          Termin buchen
        </Link>
      </li>
      <li className="nav-item">
        <Link href="#contact-us"
          to="contact-us"
          spy={ true }
          smooth={ true }
          duration={ 0 }
          offset={-70}
          activeClass="active"
          onSetActive={handleSetContactUsActive}
          className="contact-us nav-link scroll-to-id">
          Kontakt
        </Link>
      </li>
    </ul>
  );
};

export default Nav;
