import React from 'react';

const Philosophy = () => {
  return (
    <section name="philosophy" id="philosophy" className="scroll-to block spacer p-top-xl">
      <div className="wrapper">
        <div className="row gutter-width-sm">
          <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12">
            <div className="title">
              <h2>Philosophie</h2>
              <div className="philosophy-text">
                <p>
                  <i>Jedes Individuum ist Kunst.</i><br />
                  Demnach sind Haare in unseren Augen nicht einfach nur Haare - sie sind ein Spiegel unseres Inneren nach außen und eine Möglichkeit, sich selbst individuell nach eigenen Wünschen zu gestalten.
                </p>
    
                <p>
                  Wir lieben, was wir tun - und stehen mit viel Leidenschaft und Kreativität hinter unserer Arbeit.
                </p>

                <p>
                  Bei uns erwartet dich eine entspannte und humorvolle Atmosphäre, die dich dazu einlädt dich rundum wohl zu fühlen und ganz du selbst zu sein. Wir nehmen uns genügend Zeit für deine Beratung, da wir dich und deine Frise besser kennenlernen möchten, um das Beste aus dir rauszuholen.
                </p>

                <p>
                  Mit unserer langjährigen Erfahrung und unserem Fachwissen setzen wir alles daran, deine Wünsche und Vorstellungen wahr werden zu lassen.
                </p>

                <p>
                  Wir scheuen vor nichts zurück, egal wie ausgefallen oder aufwändig - wir freuen uns darauf, den Weg zu deiner perfekten Frisur mit dir gemeinsam zu gehen.
                </p>
              </div>

            </div>
          </div>
        </div>

        <div className="row gutter-width-sm">
          <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12">

            <h3>Partnerfirmen</h3>

            <p>
              Uns ist es super wichtig, den nächsten Generationen einen besseren Planeten zu hinterlassen. Daher werden wir verstärkt auf umweltbewusstes Arbeiten sowie die Verwendung von nachhaltig und fair produzierten Produkten achten.
            </p>

            <p>
              Wir möchten dir die beste Qualität zusichern und verwenden daher nur Produkte, hinter denen wir auch stehen.
            </p>

            <h4>Farben</h4>

            <p>
              Für die Verwirklichung deiner Farbträume verwenden wir Produkte von Goldwell und Redken. Wir arbeiten schon seit vielen Jahren mit beiden Herstellern zusammen und können somit die Sicherung des Qualitätsstandards garantieren. Eine breite Farbpalette hilft uns präzise zu arbeiten und alle deine Wünsche umsetzen zu können.<br />
      Sprich uns gerne an falls du Fragen haben solltest.
            </p>

            <h4>Pflege/ Styling</h4>

            <p>
              Davines zählt zu den besten Herstellern von hochwertigen und umweltverträglichen Produkten. Wir stehen zu 100% hinter dem Konzept von Davines - Schönheit mit Umwelt- und Tierschutz zu verbinden. Zudem findet sich in ihrem Repertoire für jedes Haar die individuell abgestimmte Pflege mit tollen Düften, die durch ihre natürlichen Inhaltsstoffe super hautverträglich sind.
Sprich uns gerne an und wir helfen dir dabei dein individuelles Pflegeritual zusammenzustellen und damit auch noch etwas Gutes tun - was gibt's Besseres?
            </p>

            <h4>Haarverlängerung</h4>

            <p>
              Für unsere Haarverlängerungen verwenden wir ausschließlich Haare von Hairtalk.<br />
              So haarschonend, schnell und einfach war Haarverlängerung noch nie: das Hairtalk Tape Extensions System ermöglicht jeder Trägerin volles, langes und dichtes Haar in drei Längen. Die Extensions werden im "Sandwich"-Verfahren in das Eigenhaar eingearbeitet wodurch sie sich nahezu unsichtbar einfügen und für mehr Fülle und Länge sorgen. Das ganze ist super haarschonend, da weder für das Einsetzen noch das Entfernen Hitze oder Chemie verwendet wird.<br />
              Hairtalk arbeitet mit 100% europäischem Echthaar.<br />
              Falls wir dein Interesse geweckt haben, vereinbare gerne einen Beratungstermin und wir besprechen in Ruhe die vielfältigen Möglichkeiten mit dir.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Philosophy;
