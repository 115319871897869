import React from 'react';
import { Events, scroller, animateScroll } from 'react-scroll';


class Scroller extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      scrolling: false
    };
  }

  scroll(direction, e) {

    if (this.state.scrolling) {
      return;
    }

    this.setState({ scrolling: true });

    const that = this;
    Events.scrollEvent.register('end', function(to, element) {
      window.setTimeout(() => {
        that.setState({ scrolling: false });
      }, 500);
      Events.scrollEvent.remove('end');
    });

    const sections = [...document.querySelectorAll('section')].map((el) => {
      return el.getAttribute('name');
    });

    e.preventDefault();

    let currentSectionIndex = sections.indexOf(this.props.currentSection);
    let nextSection = "";

    if (direction === 'down') {
      console.log('Scrolling down.');
      nextSection = sections[currentSectionIndex + 1];

    } else {
      console.log('Scrolling up.');
      nextSection = sections[currentSectionIndex - 1];
    }

    console.log('Current section: ' + this.props.currentSection);
    console.log('Next section: ' + nextSection);

    scroller.scrollTo(nextSection, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -70
    });
  }

  render() {

    var that = this;

    const scrollUp = (e) => {
      animateScroll.scrollTo(0);
    };
    const scrollDown = (e) => that.scroll('down', e);

    return (
      <div id="scroller">
        <div className="scroll-arrow" id="up-arrow">
          <i
            onClick={scrollUp}
            className={
              "fas fa-chevron-up" +
              (this.props.clicked ? " clicked" : "") +
              (!this.props.hidden ? " hidden" : "")
            }
          />
        </div>
        <div className="scroll-arrow" id="down-arrow">
          <i
            onClick={scrollDown}
            className={
              "fas fa-chevron-down" +
              (this.props.clicked ? " clicked" : "") +
              (this.props.hidden ? " hidden" : "") +
              (this.state.scrolling ? " disabled" : "")
            }
          />
        </div>
      </div>
    );
  }

};

export default Scroller;
