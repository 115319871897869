import React from 'react';

const HeaderLogo = () => {
    return (
      <a className="navbar-brand" title="Logo" href={ process.env.PUBLIC_URL + "/" }>
        <img src={ "assets/img/secare/logo.svg" } alt="Logo" />
      </a>
    );
};

export default HeaderLogo;
