import React from 'react';
import { Element } from 'react-scroll';

const AboutsUs = () => {
  return (
    <section id="prices" name="prices" className="scroll-to block spacer p-top-xl">
      <div className="wrapper">
        <div className="row gutter-width-sm">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <div className="title">
              <h2>Preise</h2>
            </div>
          </div>
        </div>

        <div className="row gutter-width-sm justify-content-start">
          <div className="col-xl-6 col-lg-6 col-md-9 col-sm-12">
            <div className="description">
              <p>
                Deine Haare sind besonders und einzigartig - genauso individuell passen wir unsere Preise an dich an. Wir möchten die Preise für alle fair gestalten, daher berechnen wir sowohl nach Haarlänge und -dichte als auch nach Material- und Zeitaufwand.
              </p>
              <p>
                Da uns Preistransparenz sehr wichtig ist, erläutern wir dir vor jedem Termin sowie im separaten Beratungsgespräch ausführlich wie sich der Preis zusammensetzt.
              </p>
              <p style={{fontWeight: "bold"}}>
                Wichtig: Die Inflation hat leider auch vor unserer Branche nicht Halt gemacht, weshalb wir uns aufgrund gestiegener Kosten gezwungen sehen, unsere Preise geringfügig anzupassen. Die folgenden Beträge gelten ab dem 01.11.22.
              </p>
            </div>
          </div>
        </div>

        <div className="row gutter-width-sm justify-content-start">
          <div className="col-xl-6 col-lg-6 col-md-9 col-sm-12">

            <div className="prices">

              <h4>Beratung</h4>

              <div className="price">
                <span className="item">
                  Beratungsgespräch
                </span>
                <span className="amount">20€</span>
              </div>
              <p className="price-annotation">
                (Für alle Dienstleistungen kann separat und vorab ein Beratungstermin gebucht werden, solltest du die Dienstleistung von uns durchführen lassen werden die 20€ am Ende verrechnet.)

              </p>

              <h4>Damen</h4>
              <div className="price">
                <span className="item">
                  Waschen Schneiden Föhnen kurz
                </span>
                <span className="amount">49€</span>
              </div>
              <div className="price">
                <span className="item">
                  Waschen Schneiden Föhnen Lang
                </span>
                <span className="amount">60€</span>
              </div>

              <div className="price">
                <span className="item">
                  Waschen Föhnen Styling
                </span>
                <span className="amount">ab 28€</span>
              </div>

              <h4>Herren</h4>
              <div className="price">
                <span className="item">
                  Schnitt
                </span>
                <span className="amount">40€</span>
              </div>
              <div className="price">
                <span className="item">
                  Maschinenschnitt
                </span>
                <span className="amount">22€</span>
              </div>


              <h4>Kinder</h4>

              <div className="price">
                <span className="item">
                  Jungen bis 12 Jahre
                </span>
                <span className="amount">25€</span>
              </div>
              <div className="price">
                <span className="item">
                  Mädchen bis 12 Jahre
                </span>
                <span className="amount">28€</span>
              </div>


              <h4>Zusatz Goodies</h4>

              <div className="price">
                <span className="item">
                  Pflege Kurzhaar
                </span>
                <span className="amount">10€</span>
              </div>
              <div className="price">
                <span className="item">
                  Pflege Langhaar
                </span>
                <span className="amount">15€</span>
              </div>

              <div className="price">
                <span className="item">
                  Eslabondexx
                </span>
                <span className="amount">ab 44€</span>
              </div>
              <p className="price-annotation">(Anwendung im Salon + Heimpflegeprodukt)</p>
              <div className="price">
                <span className="item">
                  Augenbrauen zupfen
                </span>
                <span className="amount">7€</span>
              </div>
              <div className="price">
                <span className="item">
                  Augenbrauen färben
                </span>
                <span className="amount">7€</span>
              </div>


              <h4>Farbveränderungen</h4>

              <div className="price">
                <span className="item">
                  Ansatzcoloration
                </span>
                <span className="amount">ab 45€</span>
              </div>
              <div className="price">
                <span className="item">
                  Coloration + Längenausgleich
                </span>
                <span className="amount">ab 65€</span>
              </div>
              <div className="price">
                <span className="item">
                  Elumen
                </span>
                <span className="amount">ab 55€</span>
              </div>

              <div className="price">
                <span className="item">
                  Strähnen / Highlights
                </span>
                <span className="amount">ab 55€</span>
              </div>

              <div className="price">
                <span className="item">
                  Balayage
                </span>
                <span className="amount">ab 90€</span>
              </div>
              <div className="price">
                <span className="item">
                  Glossing
                </span>
                <span className="amount">ab 38€</span>
              </div>
              <div className="price">
                <span className="item">
                  Dauerwelle
                </span>
                <span className="amount">ab 70€</span>
              </div>


              <h4>Haarverlängerung & Haarverdichtung</h4>

              <div className="description">
                <p>
                  Da der Preis hier stark variieren kann, erstellen wir dir nach einem persönlichen Beratungstermin deinen individuellen Kostenvoranschlag.
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    );
};

export default AboutsUs;
