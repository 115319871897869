import React from 'react';
import HeaderLogo from '../../blocks/logo/HeaderLogo';
import Nav from './Nav';
import SideDrawer from './SideDrawer';
import Scroller from '../Scroller'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

window.bodyScrollDisabled = false;

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      arrowActive: false,
      arrowHidden: false,
      currentSection: 'page-title',
      sideDrawerVisisble: false
    };
  }

  // Disable body scroll when drawer is visible
  componentWillUpdate(nextProps, nextState) {
    if (nextState.sideDrawerVisisble) {
      disableBodyScroll(document.body);
      window.bodyScrollDisabled = true;
    } else {
      if (window.bodyScrollDisabled) {
        enableBodyScroll(document.body);
        window.bodyScrollDisabled = false;
      }
    }
  }

  handleScroll = (to, element) => {
    const arrowActive = window.pageYOffset > 100;
    this.setState({ arrowActive: arrowActive });
  }

  handleResize = (to, element) => {
    const device = window.innerWidth < 768 ? 'mobile' : 'desktop';
    this.setState({ device: device });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }


  render() {

    const setSection = (section) => {
      this.setState({
        arrowActive: !(section === 'page-title'),
        arrowHidden: (section === 'contact-us'),
        currentSection: section
      });
    };

    const toggleSideDrawer = (visible=true) => {
      this.setState({
        sideDrawerVisisble: visible
      });
    }

    const closeSideDrawer = () => {
      toggleSideDrawer(false);
    }

    const setSectionOnSideDrawer = (section) => {
      window.setTimeout(() => setSection(section), 0);
      closeSideDrawer();
    }

    let renderNav = this.props.renderNav;

    return (
      <>
        { renderNav && <Scroller
          hidden={this.state.arrowHidden}
          clicked={this.state.arrowActive}
          currentSection={this.state.currentSection}
        /> }


        <header id="header" className="site-header">
          <div className="header-content">
            <SideDrawer
              handleClose={closeSideDrawer}
              handleSetSection={setSectionOnSideDrawer}
              visible={this.state.sideDrawerVisisble}
            />

            <nav className="navbar-expand-md navbar navbar-light fixed-top">
              <HeaderLogo logoColor="white" />
              <button
                className="navbar-toggler" type="button"
                onClick={toggleSideDrawer}
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>


              <div className="collapse navbar-collapse" id="navbar-collapse">
                { renderNav && <Nav for="desktop" handleSetSection={setSection} /> }
              </div>
            </nav>
          </div>
        </header>
      </>
    );
  }
};

export default Header;
