import React from 'react';

const ContactsUs = () => {
  return (
    <section name="contact-us" id="contact-us" className="bg-pink-light scroll-to spacer block including-image no-padding p-top-xl">

      <div className="text-wrapper text-center">
        <div className="title">
          <h2>Kontaktiere uns</h2>
        </div>
        <div className="content">
          <p>Tel.:  <a href="tel:+493054840448">(030) 54840448</a></p>
          <p>Adresse: <a href="https://goo.gl/maps/edMGyuTodQ6uzJfK7">Danziger Str. 63, 10435 Berlin</a></p>
        </div>

        <div className="button-wrapper">
          <a href="mailto:secare.friseur@gmail.com" className="btn btn-outline-secondary">E-Mail</a>
        </div>
      </div>


      <div className="block img img-gray"></div>
    </section>
  );
};

export default ContactsUs;
