import React from 'react';

const Copyright = () => {
    return (
        <div className="copyright">
          <p>© 2021 Secare Friseursalon</p>
        </div>
    );
};

export default Copyright;
