import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';
import page404 from './pages/404';
import UserInterface from './pages/UserInterface';

function App() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Router>
      <Switch>
        <Route
          exact
          path={ `${ process.env.PUBLIC_URL + "/" }` }
          component={ Home }
        />
        <Route
          exact
          path={ `${ process.env.PUBLIC_URL + "/impressum" }` }
          component={ Imprint }
        />
        <Route
          exact
          path={ `${ process.env.PUBLIC_URL + "/datenschutz" }` }
          component={ Privacy }
        />
        <Route
          exact
          path={ `${ process.env.PUBLIC_URL + "/ui" }` }
          component={ UserInterface }
        />
        <Route exact component={ page404 } />
      </Switch>
    </Router>
  );
}

export default App;
