import React from 'react';
import { Link, Element } from 'react-scroll';

const PageTitleHome = () => {
  return (
    <section name="page-title" id="page-title" className="page-title block with-img" style={ { backgroundImage: 'url(assets/img/secare/secare.jpg)' } }>
      <div className="wrapper text-center d-flex">
        <div className="align-self-center w-100">
          <div className="title">
            <h1 className="large">Secare Salon</h1>
          </div>

          <div className="description spacer p-top-sm">
            <p>Since 2021</p>
          </div>

        </div>
      </div>

    </section>
  );
};

export default PageTitleHome;
