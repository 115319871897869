import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import AboutUs from '../blocks/pages/AboutUs';
import Philosophy from "../blocks/pages/Philosophy";
import Book from "../blocks/pages/Book";
import ContactUs from "../blocks/pages/ContactUs";
import PageTitleHome from "../blocks/page-title/PageTitleHome";
import Prices from "../blocks/pages/Prices";


class Home extends React.Component {

  constructor(props) {
    super(props);
    document.body.classList.add( 'home' );
    document.body.classList.add( 'header-absolute-true' );

  }

  render() {
    return (
      <Fragment>
        <MetaTags>
          <meta charSet="UTF-8" />
          <title>Impressum | Secare Friseursalon</title>

          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="description" content="Secare Friseursalon - Unsere Aufgabe: Menschen verschönern und ihnen helfen, sich von ihrer besten Seite zu zeigen!" />
          <meta name="keywords" content="friseur berlin" />
          <meta name="robots" content="index, follow, noodp" />
          <meta name="googlebot" content="index, follow" />
          <meta name="google" content="notranslate" />
          <meta name="format-detection" content="telephone=no" />
          <meta property="og:title" content="Secare Friseursalon Berlin" />
          <meta property="og:description" content="Unsere Aufgabe: Menschen verschönern und ihnen helfen, sich von ihrer besten Seite zu zeigen!" />
          <meta property="og:url" content="https://secarehair.de" />
          <meta property="og:image" content="https://secarehair.de/assets/img/secare/logo.svg" />
        </MetaTags>

        <Header logoColor="light" />

        <main id="main" className="site-main content-no-spacing">
          <div className="content">
            <div id="imprint" className="clearfix">
							<h1>Impressum</h1>

							<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
							<p>Paula Schlawig und Ann-Christine Schorb GbR<br />
							Danziger Strasse 63<br />
							10435 Berlin</p>

							<p><strong>Vertreten durch:</strong><br />
							Paula Schlawig <br />
							Ann-Christine Schorb</p>

							<h2>Kontakt</h2>
							<p>Telefon: 030- 54840448<br />
							E-Mail: secare.friseur@gmail.com</p>

							<h2>Umsatzsteuer-ID</h2>
							<p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
							DE345233333</p>

							<h2>EU-Streitschlichtung</h2>
							<p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

							<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
							<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

							<p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>
													</div>
          </div>
        </main>

        <Footer />
      </Fragment>
    );
  }
};

export default Home;
