import React from 'react';

const Copyright = () => {
    return (
        <div className="imprint-menu">
          <a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutz</a>
        </div>
    );
};

export default Copyright;
